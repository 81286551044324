import photo2 from "../images/husa.png";
import photo from "../images/zverina.png";
export const akce = [
    // {
    //     id: 1,
    //     name: "Halloween na konopáči",
    //     when: "30 října",
    //     text: "Dětský Halloween, soutěž o nejlepší masku, dětská diskotéka ",
    //     photos: photo,
    //     text2: " něco ",
    // },
    {
        id: 2,
        name: " Zvěřinové hody",
        when: "1. až 3. listopadu",
        text: `Přijďte na kančí, dančí, jelení - zvěřinové pochoutky na Arabele.`,
        photos: photo,
        text2: " něco ",
    },
    {
        id: 3,
        name: "Svatomartinské husy",
        when: "8. až 10. listopadu",
        text: "Svatomartinské husy, kachny a vína na Arabele.",
        photos: photo2,
        text2: " něco ",
    },
];
