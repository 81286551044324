import React from "react";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
const customStyles = {
    content: {
        margin: "4rem",
        background: `${"var(--second-color)"}`,
        width: "max-content",
        height: "max-content",
        maxHeight: "100vh",
        overflowY: "auto",
        // border: "1px solid black",
        margin: "auto",
        padding: "1rem",
        marginTop: "1rem",
        color: `${"var(--main-color)"}`,
        borderRadius: "2rem",
    },
    overlay: { zIndex: 10 },
};
Modal.setAppElement("#root");
const ModalComponent = ({ setIsOpen, modalIsOpen, closeModal, data }) => {
    // console.log(data.photos.mainPhoto.url);
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="modal"
            style={customStyles}
            contentLabel="Action detail"
        >
            <Wrapper>
                <div className="container" key={data.id}>
                    <img src={data.photos} alt={data.name} />
                    <h1>{data.name}</h1>
                    <p className="container">{data.text}</p>
                    <button className="btn shadow" onClick={() => closeModal()}>
                        {" "}
                        zavřít
                    </button>
                </div>
            </Wrapper>
        </Modal>
    );
};

export default ModalComponent;

const Wrapper = styled.div`
    h3 {
        font-size: 1.5rem;
        color: var(--main-color);
    }
    h1 {
        color: var(--main-color);
    }
    p {
        font-size: 0.9rem;
        padding-bottom: 5px;
        color: white;
    }
    img {
        background: red;
        width: 60%;
        height: 37%;

        padding: 1rem;
    }
    .container {
        color: white;
    }
`;
