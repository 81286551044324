import React, { useEffect, useState } from "react";
import styled from "styled-components";
import foto from "../images/background.jpeg";
import ModalComponent from "./ModalComponent";
import { akce } from "../util/dataAkce";
import device from "../css/styledComp";

const Actions = () => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [data, setData] = useState("");

    function closeModal() {
        setIsOpen(false);
    }
    function openModal() {
        setIsOpen(true);
    }
    const filterMyModal = (id) => {
        openModal();
        const info = akce.filter((item) => item.id === id);

        setData(info[0]);
    };
    return (
        <>
            <Wrapper id="actions">
                <div className="title">
                    <h1>Nadcházející akce</h1>
                    <div className="underline2"></div>
                </div>
                <div className="all-actions">
                    {akce.map((item) => {
                        const { id, name, text, photos, when, text2 } = item;
                        return (
                            <div key={id} className="container shadow">
                                <div className="image-box">
                                    <img
                                        src={photos}
                                        alt="aktualní akce"
                                        className="img "
                                    />
                                </div>
                                <div className="text-box">
                                    <h3>{name}</h3>
                                    <h5 className="font-color">
                                        <span>Kdy? &nbsp;</span>
                                        {when}
                                    </h5>{" "}
                                    <p>{text}</p>
                                    {/* <button
                                        className="btn"
                                        onClick={() => filterMyModal(id)}
                                    >
                                        Detail
                                    </button> */}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <ModalComponent
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    closeModal={closeModal}
                    data={data}
                />
            </Wrapper>
        </>
    );
};

export default Actions;

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
  
    .image-box {
        height: 220px;
        width: 100%;
        border-bottom: 1px solid var(--main-color);
    }
    .all-actions {
        display: flex;
        flex-grow: inherit;
        width: 100%;
        margin: auto;
        /* padding: 1rem; */
        justify-content: center;
        /* background-color: var(--main-color); */
        align-items: center;
        border-radius: 2rem;
    }
    @media ${device.tab800} {
        .all-actions {
            flex-direction: column;
        }
    }

    .img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    .container {
        height: 420px;
        background-color: var(--second-color);
        width: 400px;
        border-radius: 1rem;
        overflow: hidden;
        text-align: start;
        margin: 2rem;
        border: 5px solid var(--main-color);
    }
    @media ${device.mobileM} {
        .container {
        height: 420px;
        background-color: var(--second-color);
        width: 360px;
        border-radius: 1rem;
        overflow: hidden;
        text-align: start;
        margin: 2rem;
        border: 5px solid var(--main-color);
    }
    h3 {
        font-size: 1.5rem;
        color: var(--main-color);
        text-align: center;
    }
    h5 > span {
        font-size: 1rem;
        color: var(--main-color);
    }
    h5 {
        font-size: 0.9rem;
    }
    }
    h1 {
        margin: 1.5rem 0rem;
        color: var(--main-color);
        font-size: 3.5rem;
        /* text-decoration: underline; */
    }
    .text-box {
        padding: 1rem;
    }
    .container:hover {
        transform: scale(1.02);
    }
    h3 {
        font-size: 2rem;
        color: var(--main-color);
        
        text-align: center;
    }
    h5 > span {
        font-size: 1.2rem;
        color: var(--main-color);
    }
    h5 {
        font-size: 1.1rem;
    }
    p {
        font-size: 1.1rem;
        padding-bottom: 5px;
        color: white;
    }
`;
